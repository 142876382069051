import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = 'https://customer.ztxinde.com/'

//请求拦截器
axios.interceptors.request.use(function (config) {
    config.headers['Token'] = sessionStorage.getItem('Token')
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 导出接口数据

// --------------------------------------------------------------------------------------
// 登录接口
export const getLoginApi = postData => {
    return axios.post('login_in', qs.stringify(postData)).then(res => res.data)
}
// 退出接口
export const loginOutApi = postData => {
    return axios.post('login_out', qs.stringify(postData)).then(res => res.data)
}
// *********************************************************************
// 权限首页
export const getAuthApi = postData => {
    return axios.post('rule_index', qs.stringify(postData)).then(res => res.data)
}
// 权限   添加编辑数据展示
export const showAuthApi = postData => {
    return axios.post('rule_data', qs.stringify(postData)).then(res => res.data)
}
// 权限   添加数据
export const addAuthApi = postData => {
    return axios.post('rule_add', qs.stringify(postData)).then(res => res.data)
}
// 权限   编辑数据
export const editAuthApi = postData => {
    return axios.post('rule_mdify', qs.stringify(postData)).then(res => res.data)
}
// 权限   删除数据
export const detelAuthApi = postData => {
    return axios.post('rule_remove', qs.stringify(postData)).then(res => res.data)
}
// ******************************************************************************
// 权限组  首页展示
export const getPermissApi = postData => {
    return axios.post('group_index', qs.stringify(postData)).then(res => res.data)
}
// 权限组  添加编辑展示
export const showPermissApi = postData => {
    return axios.post('group_data', qs.stringify(postData)).then(res => res.data)
}
// 权限组  添加
export const addPermissApi = postData => {
    return axios.post('group_add', qs.stringify(postData)).then(res => res.data)
}
// 权限组  编辑
export const editPermissApi = postData => {
    return axios.post('group_mdify', qs.stringify(postData)).then(res => res.data)
}
// 权限组  删除
export const deletPermissApi = postData => {
    return axios.post('group_remove', qs.stringify(postData)).then(res => res.data)
}
// ******************************************************************************
// 角色管理 合作方首页展示
export const getPartRoleApi = postData => {
    return axios.post('auth_user_partner_index', qs.stringify(postData)).then(res => res.data)
}
// 角色管理 合作方  添加编辑展示  
export const showPartRoleApi = postData => {
    return axios.post('auth_user_partner_data', qs.stringify(postData)).then(res => res.data)
}
// 角色管理 合作方  添加 
export const addPartRoleApi = postData => {
    return axios.post('auth_user_partner_add', qs.stringify(postData)).then(res => res.data)
}
// 角色管理 合作方  编辑 
export const editPartRoleApi = postData => {
    return axios.post('auth_user_partner_mdify', qs.stringify(postData)).then(res => res.data)
}
// 角色管理 合作方  删除 
export const deletPartRoleApi = postData => {
    return axios.post('auth_user_partner_remove', qs.stringify(postData)).then(res => res.data)
}
// *******************************************************************************************
// 角色管理 admin  首页展示 
export const getAdminApi = postData => {
    return axios.post('auth_user_admin_index', qs.stringify(postData)).then(res => res.data)
}
// 角色管理 admin  添加编辑展示
export const showAdminApi = postData => {
    return axios.post('auth_user_admin_data', qs.stringify(postData)).then(res => res.data)
}
// 角色管理 admin  添加
export const addAdminApi = postData => {
    return axios.post('auth_user_admin_add', qs.stringify(postData)).then(res => res.data)
}
// 角色管理 admin  编辑
export const editAdminApi = postData => {
    return axios.post('auth_user_admin_mdify', qs.stringify(postData)).then(res => res.data)
}
// 角色管理 admin  删除
export const deletAdminApi = postData => {
    return axios.post('auth_user_admin_remove', qs.stringify(postData)).then(res => res.data)
}
// *********************************************************************
// 合作方管理   首页展示
export const getcompApi = postData => {
    return axios.post('part_index', qs.stringify(postData)).then(res => res.data)
}
// 合作方管理  添加编辑展示
export const showcompApi = postData => {
    return axios.post('part_data', qs.stringify(postData)).then(res => res.data)
}
// 合作方管理  添加
export const addcompApi = postData => {
    return axios.post('part_add', qs.stringify(postData)).then(res => res.data)
}
// 合作方管理  编辑
export const editcompApi = postData => {
    return axios.post('part_mdify', qs.stringify(postData)).then(res => res.data)
}
// 合作方管理  删除
export const deletcompApi = postData => {
    return axios.post('part_remove', qs.stringify(postData)).then(res => res.data)
}
// ***************************************************************
// 业务人员   首页机构展示
export const getSalesApi = postData => {
    return axios.post('servicer_list', qs.stringify(postData)).then(res => res.data)
}
// 业务人员   首页具体公司人员展示
export const postSalesApi = postData => {
    return axios.post('servicer_index', qs.stringify(postData)).then(res => res.data)
}
// 业务人员   具体公司人员  添加修改数据展示
export const showSalesApi = postData => {
    return axios.post('servicer_data', qs.stringify(postData)).then(res => res.data)
}
// 业务人员   具体公司人员  添加
export const addSalesApi = postData => {
    return axios.post('servicer_add', qs.stringify(postData)).then(res => res.data)
}
// 业务人员   具体公司人员  修改
export const editSalesApi = postData => {
    return axios.post('servicer_mdify', qs.stringify(postData)).then(res => res.data)
}
// 业务人员   具体公司人员  删除
export const deletSalesApi = postData => {
    return axios.post('servicer_remove', qs.stringify(postData)).then(res => res.data)
}
// 业务人员   导入提交
export const loadSalesApi = postData => {
    return axios.post('servicer_import_data', qs.stringify(postData)).then(res => res.data)
}
// *********************************************************************************
// 客户管理   首页显示
export const getCustomerApi = postData => {
    return axios.post('customer_index', qs.stringify(postData)).then(res => res.data)
}
// 客户管理   添加修改展示
export const showCustomerApi = postData => {
    return axios.post('customer_data', qs.stringify(postData)).then(res => res.data)
}
// 客户管理   添加
export const addCustomerApi = postData => {
    return axios.post('customer_add', qs.stringify(postData)).then(res => res.data)
}
// 客户管理   编辑
export const editCustomerApi = postData => {
    return axios.post('customer_mdify', qs.stringify(postData)).then(res => res.data)
}
// 客户管理   删除
export const deletCustomerApi = postData => {
    return axios.post('customer_remove', qs.stringify(postData)).then(res => res.data)
}
// 客户管理   导入提交
export const postCustomerApi = postData => {
    return axios.post('customer_import_data', qs.stringify(postData)).then(res => res.data)
}
// **********************************************************
// 统计管理   展示
export const postCensusApi = postData => {
    return axios.post('cense_index', qs.stringify(postData)).then(res => res.data)
}